import { API } from "../../../utils/constants";

type ConsultingTeacherType = {
    name1: string;
    name2: string;
    mail1: string;
    mail2: string;
    url1: string;
    url2: string;
    img?: string;
}

export default function ConsultingTeacher( props : ConsultingTeacherType) {
    return (
        <div className='flex flex-col sm:flex-row gap-8 justify-center items-center sm:items-start py-8 sm:py-16'>
            <ConsultingTeacherElement name={props?.name1} title="Beratungslehrerin" description="für die Klassen 1-4" mail={props?.mail1} url={props.url2}/>
            <ConsultingTeacherElement name={props?.name2} title="Beratungslehrerin" description="für die Klassen 5-10" mail={props?.mail2} url={props.url2}/>
        </div>
    )
}

type ConsultingTeacherElementType = {
    name?: string;
    title: string;
    description: string;
    mail: string;
    url: string;
}

function ConsultingTeacherElement({ name, title, description, mail, url }: ConsultingTeacherElementType ) {
    return(
        <div className='w-56 flex flex-col'>
            <div style={{ backgroundImage:  `url(${API.img + url})`}} className='h-64 w-full bg-cover bg-center flex justify-end items-end'>
                <p className="w-full bg-black/30 text-base-100 font-medium p-1 h-fit">{name}</p>
            </div>
            <p className='font-semibold'>{title}</p>
            <p className=''>{description}</p>
            <p className='underline text-blue'>
                <a href={'mailto:'+mail}>
                    {mail}
                </a>
            </p>
        </div>
    )
}